import * as echarts from 'echarts'

export default function (element: any) {
  // 获取DOM
  const echartInstance = echarts.init(element)
  // 设置options
  const setOptions = (options: Record<string, any>) => {
    echartInstance.setOption(options)
  }
  const updateSize = () => {
    echartInstance.resize()
  }

  window.addEventListener('resize', () => {
    echartInstance.resize()
  })
  return {
    echartInstance,
    setOptions,
    updateSize,
  }
}
