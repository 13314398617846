<template>
  <div class="base-echarts">
    <div ref="echartDivRef" :style="{ ...style }"></div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watchEffect, withDefaults, watch } from 'vue'
import useEchart from './hooks/useEcharts'
// props接口定义
interface InterProps {
  style: any
  options: Record<string, any>
}
const props = withDefaults(defineProps<InterProps>(), {
  style: {
    width: '100vw',
    height: '300px',
  },
})
// 装载Echarts的容器
const echartDivRef = ref()
const exposeChart = ref()
onMounted(() => {
  // 设置
  const { setOptions, echartInstance } = useEchart(echartDivRef.value)
  exposeChart.value = echartInstance
  // 更改
  watchEffect(() => {
    setOptions(props.options)
  })
})

defineExpose({
  exposeChart,
  echartDivRef,
})
</script>

<script lang="ts">
export default {
  name: 'BaseEcharts',
}
</script>

<style lang="scss" scoped></style>
